"use client";

import Link from "next/link";
import { Facebook, Linkedin, Youtube, Instagram } from "lucide-react";
import Image from "next/image";

const LINKS = [
  {
    title: "Policies",
    items: [
      { name: "Privacy Policy", href: "/privacy-policy" },
      { name: "Terms of Service", href: "/terms-of-service" },
    ],
  },
  {
    title: "Company",
    items: [
      { name: "About Us", href: "/about-us" },
      { name: "Our Team", href: "/about-us/team" },
    ],
  },
  {
    title: "Resources",
    items: [
      { name: "Videos", href: "/videos" },
      { name: "Research Reports", href: "/content/reports" },
    ],
  },
];

const currentYear = new Date().getFullYear();

export function Footer() {
  return (
    <footer className="hidden md:block relative w-full h-[345px] bg-1A2C45 z-10">
      <div className="mx-auto w-full xl:container px-8 py-5">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          {/* Logo section */}
          <div className="mb-5 md:mb-5">
            <Link href="/" passHref>
              <div className="cursor-pointer">
                <Image
                  alt="frc-logo"
                  src="/images/frc-logo.webp"
                  className="h-auto w-28"
                  width={110}
                  height={14}
                  blurDataURL="/images/BlurBG.webp"
                  priority={true}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </Link>
          </div>

          <div className="grid grid-cols-3 pb-10 md:pb-12 justify-between gap-8">
            {LINKS.map(({ title, items }) => (
              <ul key={title} className="mt-4 md:mt-6">
                <li className="text-lg text-gray-400 font-bold opacity-32 mb-2 md:mb-4">
                  {title}
                </li>
                {items.map(({ name, href }) => (
                  <li key={name} className="py-1.5 md:py-0.5">
                    <Link href={href}>
                      <span className="text-E6E6E6 cursor-pointer font-light hover:text-1B51CA">
                        {name}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>

        {/* New disclaimer text */}
        <div className="mt-4 w-2/4 mb-4 md:mt-0 md:flex-1">
          <p className="text-xs font-light text-gray-200">
            * FRC provides issuer paid research on some of the companies
            mentioned on this website. **past performance should not be taken as
            any indication of future returns.
          </p>
        </div>
        <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <p className="mb-4 text-center text-sm text-gray-100 md:mb-0">
            &copy; {currentYear}{" "}
            <Link href="https://researchfrc.com/">
              <span className="underline cursor-pointer font-light">
                Fundamental Research
              </span>
            </Link>{" "}
            . All Rights Reserved.
          </p>

          <div className="flex gap-4 text-white sm:justify-center">
            {/* Social Icons */}
            <Link
              href="https://www.youtube.com/channel/UC0woiCXl9LLEBSI4zM3qUIQ"
              target="_blank"
              aria-label="Youtube"
            >
              <Youtube
                className="hover:text-1B51CA"
                size={24}
                color="#e6e6e6"
                strokeWidth={1.5}
              />
            </Link>
            <Link
              href="https://www.facebook.com/researchfrc"
              target="_blank"
              aria-label="Facebook"
            >
              <Facebook size={24} color="#e6e6e6" strokeWidth={1.5} />
            </Link>
            <Link
              href="https://www.linkedin.com/in/frcorp/"
              target="_blank"
              aria-label="Linkedin"
            >
              <Linkedin size={24} color="#e6e6e6" strokeWidth={1.5} />
            </Link>
            <Link
              href="https://www.instagram.com/researchfrc/"
              target="_blank"
              aria-label="Instagram"
            >
              <Instagram size={24} color="#e6e6e6" strokeWidth={1.5} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
