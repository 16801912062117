"use client";

import React, { useState } from "react";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "@nextui-org/button";

const HamburgerMenu = ({ session }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseMenu = () => setIsOpen(false);

  return (
    <>
      <div
        className="block md:hidden cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="block w-6 h-0.5 bg-white mb-1"></span>
        <span className="block w-6 h-0.5 bg-white mb-1"></span>
        <span className="block w-6 h-0.5 bg-white"></span>
      </div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 text-white flex flex-col items-center justify-center">
          <div className="absolute top-10 right-10">
            <button onClick={handleCloseMenu}>
              <XIcon className="w-8 h-8 hover:text-gray-400" />
            </button>
          </div>
          {/* Welcome message */}
          {session && (
            <div className="mb-4 text-center text-AC9576 text-2xl p-3">
              Welcome,{" "}
              <span className="text-AC9576 text-2xl font-bold">
                {session.user.name}
              </span>
            </div>
          )}
          <ul className="space-y-4 text-center">
            {!session && (
              <>
                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/about-us" passHref>
                    ABOUT US
                  </Link>
                </li>
                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/about-us/team" passHref>
                    OUR TEAM{" "}
                  </Link>
                </li>

                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/top-picks" passHref>
                    TOP PICKS
                  </Link>
                </li>
                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/videos" passHref>
                    VIDEOS
                  </Link>
                </li>

                {/* <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/plans" passHref>
                    PLANS
                  </Link>
                </li> */}
                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/sign-in" passHref>
                    <Button className=" bg-E6E6E6 w-52 text-xl mt-10">
                      SIGN IN
                    </Button>
                  </Link>
                </li>
                <li
                  className="hover:text-gray-400 cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/plans" passHref>
                    <Button
                      className="bg-1B51CA text-xl mt-4"
                      variant="default"
                      type="submit"
                    >
                      SUBSCRIBE FOR FREE
                    </Button>
                  </Link>
                </li>
              </>
            )}
            {session && (
              <>
                <li
                  className="hover:text-1B51CA text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/" passHref>
                    HOME
                  </Link>
                </li>
                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/about-us" passHref>
                    ABOUT US
                  </Link>
                </li>
                <li
                  className="hover:text-gray-400 text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/videos" passHref>
                    VIDEOS
                  </Link>
                </li>
                <li
                  className="hover:text-1B51CA text-2xl cursor-pointer"
                  onClick={handleCloseMenu}
                >
                  <Link href="/account/user-information" passHref>
                    MY ACCOUNT
                  </Link>
                </li>

                <li className="hover:text-1B51CA text-2xl mt-20 cursor-pointer">
                  <Button
                    key="logout"
                    color="danger"
                    className="mt-20"
                    onClick={() => signOut()}
                  >
                    SIGN OUT
                  </Button>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;
