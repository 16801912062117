"use client";
// DropdownMenu.jsx
import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { CaretDownIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import { FileBadge, Film, Lightbulb } from "lucide-react";

const DropdownMenu = () => {
  return (
    <NavigationMenu.Root className="relative">
      <NavigationMenu.List className="center m-0 flex list-none ">
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="text-white text-sm hover:bg-1B51CA p-2 group flex select-none items-center  gap-[2px] rounded-[9px] text-[15px] font-light  ">
            CONTENT
            <CaretDownIcon
              className="text-write relative top-[1px] transition-transform duration-250 ease-in group-data-[state=open]:-rotate-180"
              aria-hidden
            />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="absolute rounded-xl bg-E6E6E6 top-10 text-1C1C1E -left-60 w-full shadow-lg sm:w-auto">
            <ul className="m-0 grid list-none gap-x-[10px] p-7 sm:w-[550px] sm:grid-flow-col sm:grid-rows-3">
              <ListItem
                className="text-bold"
                title="Research Reports"
                href="/content/reports"
                icon={FileBadge} // Pass the FileBadge icon
              >
                In-depth analysis on companies we cover
              </ListItem>
              <ListItem
                title="Analysts' Ideas of the Week"
                href="/content/analyst-ideas"
                icon={Lightbulb} // Pass the Lightbulb icon
              >
                Weekly market commentary and analysis
              </ListItem>
              <ListItem
                title="Videos"
                href="/videos?page=1&category=aiw&sortType=publish_date&sort=desc"
                icon={Film} // Pass the Film icon
              >
                Insightful Ideas and Commentary from our Top Analysts
              </ListItem>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

const ListItem = React.forwardRef(
  ({ className, children, title, icon: Icon, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={classNames(
            "flex box items-center hover:bg-gray-600 hover:bg-opacity-20  select-none rounded-[6px] p-3 text-[15px]",
            className
          )}
          {...props}
          ref={forwardedRef}
        >
          {Icon && (
            <Icon
              className="mr-4" // Add some margin to the right of the icon
              size={32}
              strokeWidth={1.5}
              absoluteStrokeWidth
            />
          )}
          <div className="flex flex-col">
            <div className="mb-[5px] font-bold text-lg leading-[1.2]">
              {title}
            </div>
            <p className="leading-[1.4]">{children}</p>
          </div>
        </a>
      </NavigationMenu.Link>
    </li>
  )
);

ListItem.displayName = "ListItem"; // This line sets the display name

export default DropdownMenu;
