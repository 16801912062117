"use client";

import React from "react";
import { signOut } from "next-auth/react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/dropdown";
import Link from "next/link";
import { Button } from "@nextui-org/button";

const ProfileDropdown = ({ sessionData }) => {
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          auto
          flat
          className="flex items-center text-white bg-1B51CA gap-2"
        >
          Welcome, <span className="font-bold">{sessionData.user.name}</span>
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="User Actions">
        <DropdownItem key="account" className="justify-start">
          <Link href="/account/user-information" passHref>
            <span className="cursor-pointer block w-full text-left">
              My Account
            </span>
          </Link>
        </DropdownItem>
        <DropdownItem
          key="logout"
          color="danger"
          onClick={() => signOut({ callbackUrl: "/" })}
        >
          Sign Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
